.app-bar {
    background-color: white !important;
}

.drawer-logo {
    margin: 2rem 0 0 2rem;
}

.spacing {
    flex-grow: 1;
}

.nav-link {
    position: relative;
    font-family: Roboto;
    margin-right: 2rem;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-decoration: none;
    transition: all 0.5s;
}

.nav-link-active {
    color: #cc3333;
}

.nav-is-selected {
    position: absolute;
    bottom: -2.5px;
    height: 2px;
    width: 100%;
    transition: all 0.5s;
    background: #cc3333;
}

.nav-is-selected:after {
    z-index: 100;
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    margin-left: -3px;
    top: -2.5px;
    width: 6px;
    height: 6px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    background: #fff !important;
    border-right: 2px solid #cc3333;
    border-bottom: 2px solid #cc3333;
}

.menu-link {
    color: #000;
}
.menu-link:after {
    position: absolute;
    bottom: -4px;
    left: 0;
    right: 0;
    margin: 0;
    width: 0;
    content: '\A';
    background: #aaa;
    height: 2px;
    text-align: left;
}
.menu-link:hover {
    z-index: 1;
}
.menu-link:hover:after {
    z-index: -10;
    width: 100%;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    opacity: 1;
}
.menu-link:not(:hover):after {
    z-index: -10;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    width: 0;
    opacity: 1;
}

.drawer-link {
    font-size: 2rem;
    font-weight: 700;
    line-height: 6.25rem;
    font-family: Roboto;
    display: inline-block;
    text-decoration: none;
    margin-left: 2rem;
    margin-right: 2rem;
    transition: all 0.5s;
}
.drawer-link-active {
    color: #cc3333;
}

.drawer-link-default {
    color: #000;
}
