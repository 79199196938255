.exposure-field {
    width: 3rem;
    height: 1.5rem;
    border-radius: 4px;
    border: solid 1px #808080;
    text-align: center;
}

.player-cell {
    padding: 6px 18px 6px 0px !important;
    white-space: nowrap;
}
