@import url(https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,300;0,400;0,500;0,600;0,900;1,500&family=Roboto:wght@100;400;500;700;900&display=swap);
.app-bar {
    background-color: white !important;
}

.drawer-logo {
    margin: 2rem 0 0 2rem;
}

.spacing {
    flex-grow: 1;
}

.nav-link {
    position: relative;
    font-family: Roboto;
    margin-right: 2rem;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-decoration: none;
    transition: all 0.5s;
}

.nav-link-active {
    color: #cc3333;
}

.nav-is-selected {
    position: absolute;
    bottom: -2.5px;
    height: 2px;
    width: 100%;
    transition: all 0.5s;
    background: #cc3333;
}

.nav-is-selected:after {
    z-index: 100;
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    margin-left: -3px;
    top: -2.5px;
    width: 6px;
    height: 6px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    background: #fff !important;
    border-right: 2px solid #cc3333;
    border-bottom: 2px solid #cc3333;
}

.menu-link {
    color: #000;
}
.menu-link:after {
    position: absolute;
    bottom: -4px;
    left: 0;
    right: 0;
    margin: 0;
    width: 0;
    content: '\A';
    background: #aaa;
    height: 2px;
    text-align: left;
}
.menu-link:hover {
    z-index: 1;
}
.menu-link:hover:after {
    z-index: -10;
    width: 100%;
    transition: all 0.5s ease-in-out;
    opacity: 1;
}
.menu-link:not(:hover):after {
    z-index: -10;
    transition: all 0.5s ease-in-out;
    width: 0;
    opacity: 1;
}

.drawer-link {
    font-size: 2rem;
    font-weight: 700;
    line-height: 6.25rem;
    font-family: Roboto;
    display: inline-block;
    text-decoration: none;
    margin-left: 2rem;
    margin-right: 2rem;
    transition: all 0.5s;
}
.drawer-link-active {
    color: #cc3333;
}

.drawer-link-default {
    color: #000;
}

.header {
    background: #d3d3d3;
}
.header-cell {
    padding: 0.188rem !important;
    font-size: 0.75rem !important;
    line-height: 0.875rem !important;
    background: #d3d3d3 !important;
}
.title-tooltip-box {
    display: flex;
    align-items: center;
}
.tooltip {
    margin-left: 4px;
}

.exposure-field {
    width: 3rem;
    height: 1.5rem;
    border-radius: 4px;
    border: solid 1px #808080;
    text-align: center;
}

.player-cell {
    padding: 6px 18px 6px 0px !important;
    white-space: nowrap;
}

html,body{margin:0;padding:0;font-family:"Roboto",-apple-system,BlinkMacSystemFont,"Segoe UI","Helvetica Neue","Arial",sans-serif;background-color:#f2f2f2;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;height:100%}#root{height:100%;position:relative;display:flex;flex-flow:column}.logo{height:8rem;width:12rem}
