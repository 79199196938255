//@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@300;400;500;500i;600;900&family=Roboto:wght@100;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,300;0,400;0,500;0,600;0,900;1,500&family=Roboto:wght@100;400;500;700;900&display=swap');
html,
body {
    margin: 0;
    padding: 0;
    font-family:
        'Roboto',
        -apple-system,
        BlinkMacSystemFont,
        'Segoe UI',
        'Helvetica Neue',
        'Arial',
        sans-serif;
    background-color: #f2f2f2;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
}
#root {
    height: 100%;
    position: relative;
    display: flex;
    flex-flow: column;
}

@import './Common.scss';
